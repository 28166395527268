<template>
  <span
    v-clipboard:copy="content"
    v-clipboard:success="copySuccess"
    class="cursor-pointer"
  >{{ content }}</span>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toastification } from '@core/mixins/toast'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },

  mixins: [toastification],

  props: {
    content: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
    }
  },

  methods: {
    copySuccess() {
      this.toastSuccess('Copied')
    },
  },
}
</script>
