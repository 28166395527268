<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-link
          variant="warning"
          target="_blank"
          class="btn btn-primary"
          href="/shopee-vouchers/create"
        >
          Create
        </b-link>
      </b-form-group>

      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            v-debounce:300ms="handleSearch"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="shopeeVouchers && shopeeVouchers.data"
      :pagination-options="{
        enabled: true,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: { field: 'id', type: 'desc' },
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: id -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.id }}
        </span>

        <!-- Column: code -->
        <span v-else-if="props.column.field === 'code'">
          <span class="text-justify">
            <TextCopy :content="props.row.code" />
            <br>
            <TextCopy :content="props.row.signature" />
          </span>
        </span>

        <!-- Column: content -->
        <span v-else-if="props.column.field === 'content'">
          <span class="text-justify">
            <TextCopy :content="props.row.content" />
          </span>
        </span>

        <!-- Column: start_time -->
        <span
          v-else-if="props.column.field === 'start_time'"
        >
          {{ props.row.start_time }}
          <br>
          {{ props.row.end_time }}
          <br>
          {{ props.row.created_at | formatDate }}

        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              of {{ shopeeVouchers.total }} entries
            </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="shopeeVouchers && shopeeVouchers.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import TextCopy from '@core/components/TextCopy.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('shopeeVoucher')

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TextCopy,
  },

  mixins: [toastification],

  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      searchTerm: '',
      sortTerm: {
        field: '',
        type: '',
      },
      pages: ['3', '5', '10'],

      columns: [
        { label: 'Id', field: 'id' },
        {
          label: 'Code', field: 'code', tdClass: 'text-break', width: '20%',
        },
        { label: 'Content', field: 'content', tdClass: 'text-justify' },
        { label: 'Title', field: 'title' },
        { label: 'Time / Created', field: 'start_time', tdClass: 'text-nowrap' },
      ],
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading', 'shopeeVouchers']),
  },

  mounted() {
    this.currentPage = this.$route.query.page * 1 || 1
  },

  methods: {
    ...mapActions(['getShopeeVouchers']),
    copySuccess() {
      this.toastSuccess('Copied')
    },

    async handleSearch(searching) {
      this.searchTerm = searching
      this.currentPage = 1
      await this.fetchShopeeVouchers()
    },
    async handlePageChange(active) {
      this.pageLength = active
      await this.fetchShopeeVouchers()
    },
    async handleChangePage(page) {
      this.currentPage = page
      this.$router.push({ ...this.$route, query: { ...this.$route.query, page } })
      await this.fetchShopeeVouchers()
    },
    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type

      await this.fetchShopeeVouchers()
    },

    async fetchShopeeVouchers() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        sort:
          this.sortTerm.type === 'asc'
            ? `${this.sortTerm.field}`
            : `-${this.sortTerm.field}`,
        search: this.searchTerm,
      }

      await this.getShopeeVouchers(params)
    },
  },
}
</script>
